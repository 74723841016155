import React, { useState, useRef, useEffect } from 'react';
import ArrowDownIcon from '../svg/ArrowDownIcon'

interface DropdownMenuProps {
  label: string;
  actions: { label: string, classes: string, onClick: () => void }[];
  className?: string
}

const ButtonDropdown: React.FC<DropdownMenuProps> = ({ label, actions, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleItemClick = (action: () => void) => {
    action();
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="dropdown-menu-container" ref={menuRef}>
      <button onClick={toggleMenu} className={`dropdown-button ${className ?? ''}`}>
        {label} <span className={`dropdown-icon ${isOpen ? 'open' : ''}`}><ArrowDownIcon /></span>
      </button>
      {isOpen && (
        <ul className="dropdown-menu">
          {actions.map((action, index) => (
            <li key={index}>
              <button className={action.classes} onClick={() => handleItemClick(action.onClick)}>
                {action.label}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ButtonDropdown;
